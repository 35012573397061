//@flow
import React, { memo } from 'react';
import { Link } from '@reach/router';
import { Grid, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { palette } from '@dt/theme';

type Props = {
  currentTab: ?string,
  tabs: $ReadOnlyArray<{
    value: string,
    label: string,
    disabled?: boolean,
    id?: string,
  }>,
};

const useStyles = makeStyles({
  indicator: {
    backgroundColor: palette.brand30,
  },
  tab_root: {
    minWidth: 72,
    maxWidth: 'inherit',
    color: palette.gray35,
    marginRight: 8,
  },
  tab_selected: { color: palette.brand30 },
});

export default memo<Props>(function TabsComponent({ currentTab, tabs }: Props) {
  const classes = useStyles();

  return (
    <Grid container>
      <Tabs
        value={currentTab || tabs[0].value}
        classes={{ indicator: classes.indicator }}
      >
        {tabs.map(({ value, label, disabled, id }, i) => (
          <Tab
            data-testid={id ? id : label}
            value={value}
            label={label}
            aria-label={label}
            classes={{
              selected: classes.tab_selected,
              root: classes.tab_root,
            }}
            disabled={disabled || false}
            to={
              (currentTab ? '../' : '') +
              value.replace(' ', '-').toLocaleLowerCase()
            }
            component={Link}
            key={i}
          />
        ))}
      </Tabs>
    </Grid>
  );
});
