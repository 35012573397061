// @flow
import { type Saga } from 'redux-saga';
import { all, call, spawn, delay } from 'redux-saga/effects';
import type { ActionType } from 'redux-actions';

import type { PiiTypeCustomizationConfig } from '@dt/horizon-api';

import { takePatternAndFetch } from '../resource_fetch/sagas';
import { getInventoryStats } from './../dashboard/resource_fetch.sagas';

import { hackExtractMounted, hackExtractStartClicked } from './actions';

import {
  SpecialScanRequestTypeEnum,
  special_scan_requests,
} from '@dt/horizon-api';
import {
  getSpecialScanRequestTypeList,
  getAllPiiReportsInApiResponses,
} from '../special_scans/resource_fetch.sagas';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { takeEvery } from 'redux-saga/effects';
import { getAllPolicies } from '../policies/sagas';

export default function* watchForResourceFetching(): Saga<void> {
  yield all([
    call(
      takePatternAndFetch,
      hackExtractMounted.toString(),
      function*(): Saga<void> {
        yield all([
          call(getInventoryStats, {}),
          call(getAllPolicies, {}),
          call(getAllPiiReportsInApiResponses, {}),
          call(getSpecialScanRequestTypeList, {
            type: SpecialScanRequestTypeEnum.HACK_AND_EXTRACT,
          }),
        ]);
      },
    ),
    takeEvery(hackExtractStartClicked.toString(), hackExtractStartSaga),
  ]);
}

function* optimisticlyCreateScanRequest(
  piiTypeConfig: PiiTypeCustomizationConfig,
): Saga<void> {
  // NOTE: We could do more with the response here to confirm request.
  yield* callPromise(special_scan_requests.create, {
    scan_type: SpecialScanRequestTypeEnum.HACK_AND_EXTRACT,
    ...piiTypeConfig,
  });
}

function* hackExtractStartSaga(
  action: ActionType<typeof hackExtractStartClicked>,
): Saga<void> {
  // NOTE: Not having to wait for this response allows the UI to respond faster.
  //       This is extremly optimistic though since the request might have failed.
  //       We are doing this to determine if users actually use this H&E feature or not.
  yield spawn(optimisticlyCreateScanRequest, action.payload.configuration);
  //       We need to wait to provide a slightly better gurantee that the backend received the request.
  yield delay(2000);
  yield call(
    getSpecialScanRequestTypeList,
    {
      type: SpecialScanRequestTypeEnum.HACK_AND_EXTRACT,
    },
    {
      forceUpdate: true,
    },
  );
}
