// @flow
import React from 'react';
import { palette } from '@dt/theme';
import { Grid, Typography } from '@material-ui/core';
import formatDate from 'date-fns/format';
import DoneIcon from '@material-ui/icons/Done';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { makeStyles } from '@material-ui/styles';

type Props = {
  lastScanStatus: string,
  lastScanDateCreated: string | Date,
  discoveredDate: Date,
};

const useStyles = makeStyles({
  protectedContainer: {
    display: 'flex',
    width: 140,
    padding: 4,
    '& svg': {
      color: palette.green30,
    },
    '& p': {
      color: palette.green30,
    },
  },
  unprotectedContainer: {
    display: 'flex',
    width: 140,
    padding: 4,
    '& svg': {
      color: palette.red30,
      fontSize: 20,
    },
    '& p': {
      color: palette.red30,
    },
  },
  wontFixContainer: {
    display: 'flex',
    width: 140,
    padding: 4,
    '& svg': {
      color: palette.gray30,
      fontSize: 20,
    },
    '& p': {
      color: palette.gray30,
    },
  },
  icon: {
    borderRadius: 100,
    width: 24,
    height: 24,
  },
  text: {
    color: palette.white,
    paddingLeft: 2,
  },
  discovered: {
    padding: '2px 4px',
    borderRadius: 2,
    fontSize: 14,
    backgroundColor: palette.yellow40,
    color: palette.gray30,
    minWidth: 220,
  },
  lastTest: {
    padding: '2px 4px',
    borderRadius: 2,
    fontSize: 14,
    backgroundColor: palette.gray50,
    color: palette.gray30,
    minWidth: 220,
  },
});

function ToolkitAssetTableLastScanStatus({
  lastScanStatus,
  lastScanDateCreated,
  discoveredDate,
}: Props) {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        {lastScanStatus === 'Protected' ? (
          <div className={classes.protectedContainer}>
            <Typography className={classes.icon}>
              <DoneIcon />
            </Typography>
            <Typography className={classes.text}>Protected</Typography>
          </div>
        ) : null}
        {lastScanStatus === 'Unprotected' ? (
          <div className={classes.unprotectedContainer}>
            <Typography className={classes.icon}>
              <ReportProblemIcon />
            </Typography>
            <Typography className={classes.text}>Unprotected</Typography>
          </div>
        ) : null}
        {lastScanStatus === 'WontFix' ? (
          <div className={classes.wontFixContainer}>
            <Typography className={classes.icon}>
              <ReportProblemIcon />
            </Typography>
            <Typography className={classes.text}>Wont Fix</Typography>
          </div>
        ) : null}
      </Grid>
      <Grid item xs={6}>
        <Grid container>
          {lastScanStatus === 'no-scan' ? (
            <Grid container className={classes.discovered} justify="center">
              New - Discovered on {formatDate(discoveredDate, 'MMM D, YYYY')}
            </Grid>
          ) : (
            <Grid container className={classes.lastTest} justify="center">
              Last tested on {formatDate(lastScanDateCreated, 'MMM D, YYYY')}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default React.memo<Props>(ToolkitAssetTableLastScanStatus);
