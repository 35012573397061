//@flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AppScanBlockedReasonEnum from '@dt/enums/AppScanBlockedReasonEnum';
import { type AppScanBlockedReasonEnum as AppScanBlockedReasonEnumType } from '@dt/enums/AppScanBlockedReasonEnum';
import { type ApplicationScan } from '@dt/user-api/mobile_apps';
import { palette } from '@dt/theme';
import ProgressIcon from '@material-ui/icons/Timelapse';
import ExclamationPointIcon from '@material-ui/icons/Error';
import CheckMarkIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  label: {
    color: palette.navColor,
    paddingLeft: ({ includeImage }: Props) => (includeImage ? 5 : 'unset'),
  },
});

type DefaultProps = {|
  format: (
    {
      +start_date?: string,
      +end_date?: string,
      ...
    } | void,
    AppScanBlockedReasonEnumType,
  ) => string,
  includeImage: boolean,
  includeLabel: boolean,
|};

type Props = {|
  scan?: ApplicationScan,
  type?: AppScanBlockedReasonEnumType,
  overrideLabel?: string,
  ...DefaultProps,
|};

function ScanBadge(props) {
  const {
    scan,
    includeImage,
    includeLabel,
    overrideLabel,
    format,
    type,
  } = props;
  const classes = useStyles();

  const scanDetails =
    !overrideLabel && type ? format(scan, type) : overrideLabel;

  return (
    <div className={classes.container}>
      {includeImage ? (
        <BadgeImage scanDetails={scanDetails} scan={scan} type={type} />
      ) : null}
      {includeLabel ? (
        <span className={classes.label}>{scanDetails}</span>
      ) : null}
    </div>
  );
}

ScanBadge.defaultProps = {
  format: (
    scan: void | {
      +start_date?: string,
      +end_date?: string,
      ...
    },
    reason: AppScanBlockedReasonEnumType,
  ) => reason,
  includeLabel: true,
  includeImage: true,
};

const BadgeImage = ({ scanDetails, scan, type }) => {
  const style = {
    width: 16,
    height: 16,
    margin: 0,
  };

  const exclamationPoint = (
    <span style={{ color: palette.red }}>
      <ExclamationPointIcon style={style} titleAccess={scanDetails} />
    </span>
  );

  // No scan.
  if (!scan) {
    return exclamationPoint;
  }

  // No scan started.
  if (!scan.start_date) {
    return exclamationPoint;
  }

  // Scan blocked.
  if (
    type === AppScanBlockedReasonEnum.CORRUPTED_APP_PACKAGE ||
    type === AppScanBlockedReasonEnum.NO_CREDENTIALS
  ) {
    return exclamationPoint;
  }

  // Scan ongoing.
  if (!scan.end_date) {
    return (
      <span style={{ color: palette.yellow }}>
        <ProgressIcon style={style} titleAccess={scanDetails} />
      </span>
    );
  }

  // Scan completed.
  return (
    <span style={{ color: palette.green }}>
      <CheckMarkIcon style={style} titleAccess={scanDetails} />
    </span>
  );
};

export default React.memo<Props>(ScanBadge);
