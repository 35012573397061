//@flow
const appScanBlockedReason = {
  CORRUPTED_APP_PACKAGE: 'CORRUPTED_APP_PACKAGE',
  NO_CREDENTIALS: 'NO_CREDENTIALS',
  NOT_BLOCKED: 'NOT_BLOCKED',
  OTHER: 'OTHER',
};

export default appScanBlockedReason;

export type AppScanBlockedReasonEnum = $Keys<typeof appScanBlockedReason>;
