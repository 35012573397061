// @flow
import React, { memo, useMemo } from 'react';
import format from 'date-fns/format';
import addDays from 'date-fns/add_days';
import { Grid } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LaunchIcon from '@material-ui/icons/Launch';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { palette } from '@dt/theme';
import TagHostedOn from '../TagHostedOn';
import Text from '../Text';
import ToolkitTableLastScanStatus from './ToolkitAssetTableLastScanStatus';
import ToolkitTableSelectedRowDetails from './ToolkitAssetTableSelectedRowDetails';
import ToolkitTableSelectedRowScanProgress from './ToolkitAssetTableSelectedRowScanProgress';
import type {
  PolicyViolationsListQueryVersion2Query,
  SpecialScanStatus,
} from '@dt/graphql-support/types';
import {
  HostedOnValues,
  SpecialScanStatusValues,
  type AssetTypeEnum,
  AssetTypeEnumValues,
  PolicyViolationStatusEnumValues,
  PolicyViolationExceptionValues,
} from '@dt/graphql-support/types';

const useStyles = makeStyles({
  row: props => ({
    backgroundColor: props.backgroundColor,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: palette.gray50,
    },
  }),
  cell: {
    padding: 8,
  },
  text: {
    width: 400,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  scanHistoryBlock: {
    borderRadius: 2,
    margin: 2,
    width: 14,
    minWidth: 14,
    maxWidth: 14,
    height: 14,
    minHeight: 14,
    maxHeight: 14,
    "&[data-color='red']": {
      background: palette.red30,
    },
    "&[data-color='green']": {
      background: palette.green30,
    },
    "&[data-color='gray']": {
      background: palette.gray35,
    },
  },
});

type PolicyViolationsArrayType = $PropertyType<
  $PropertyType<
    PolicyViolationsListQueryVersion2Query,
    'policy_violations_list_v2',
  >,
  'policy_violations',
>;

type ScanType = {
  current_status: SpecialScanStatus,
  date_created: Date,
  ...
};

type Props = {
  asset: $ElementType<PolicyViolationsArrayType, 0>,
  scans: $ReadOnlyArray<ScanType>,
  policyViolationPath: string,
  portalPath: string,
  openRowId: ?string,
  onClick: ($ElementType<PolicyViolationsArrayType, 0>) => void,
};

const assetTypeToPath: { [k: AssetTypeEnum]: string, ... } = Object.freeze({
  [AssetTypeEnumValues.CLOUD_RESOURCE]: 'cloud-resources',
  [AssetTypeEnumValues.NETWORK_SERVICE]: 'network-services',
  [AssetTypeEnumValues.RESTFUL_API]: 'restful-apis',
  [AssetTypeEnumValues.WEB_APPLICATION]: 'web-applications',
  [AssetTypeEnumValues.API_OPERATION]: 'api-operations',
});

function ToolkitAssetTablePolicyViolationRow({
  asset,
  scans,
  policyViolationPath,
  portalPath,
  openRowId,
  onClick,
}: Props) {
  const isRowOpen = Boolean(asset.id === openRowId);

  const classes = useStyles({
    backgroundColor: isRowOpen ? palette.gray50 : null,
  });

  const scanHistory = useMemo(
    () =>
      scans
        ?.slice(0, 5)
        .filter(
          scan =>
            format(scan.date_created) >=
            format(asset.affected_asset.date_created),
        )
        .map((scan: ScanType) => {
          const isUnprotected = Boolean(
            asset.violated_policy_rule &&
              format(asset.date_created) <= format(scan.date_created) &&
              (!asset.date_resolved ||
                format(asset.date_resolved) >= format(scan.date_created)),
          );

          const hasWontFixPolicyViolations = Boolean(
            asset.violated_policy_rule &&
              format(asset.date_created) <= format(scan.date_created) &&
              asset.exception_type ===
                PolicyViolationExceptionValues.WONT_FIX &&
              asset.exception_date_created &&
              format(asset.exception_date_created) <= format(scan.date_created),
          );

          if (hasWontFixPolicyViolations) {
            return {
              description: `${format(
                scan.date_created,
                'YYYY-MM-DD',
              )} | Won't Fix`,
              color: 'gray',
            };
          }

          if (isUnprotected) {
            return {
              description: `${format(
                scan.date_created,
                'YYYY-MM-DD',
              )} | Unprotected`,
              color: 'red',
            };
          }

          return {
            description: `${format(
              scan.date_created,
              'YYYY-MM-DD',
            )} | Protected`,
            color: 'green',
          };
        }),
    [scans, asset],
  );

  /**
   * there is a status history show scan stats (protected | unprotected ) for last five scan days
   * since we are showing resolved policy violations on table
   * this logic make the first status history block red (unprotected)
   * if resolved day is before five days ago ( last five scan days )
   */
  if (
    asset.date_resolved &&
    format(asset.date_resolved) <= format(addDays(new Date(), -5))
  ) {
    scanHistory[0].color = 'red';
    scanHistory[0].description = `${format(
      asset.date_resolved,
      'YYYY-MM-DD',
    )} | Unprotected`;
  }

  /**
   *  make first block red if exception_type is won't fix and
   * 'exception created date' is grater than 'created date'
   *  that means asset was unprotected in some point and
   *  later it turned to won't fix status
   */
  if (
    asset.exception_type === PolicyViolationExceptionValues.WONT_FIX &&
    asset.exception_date_created &&
    format(asset.exception_date_created) > format(asset.date_created)
  ) {
    scanHistory[0].color = 'red';
    scanHistory[0].description = `${format(
      asset.exception_date_created,
      'YYYY-MM-DD',
    )} | Unprotected`;
  }

  return (
    <>
      <TableRow classes={{ root: classes.row }} onClick={() => onClick(asset)}>
        <TableCell classes={{ root: classes.cell }}>
          <Grid container spacing={1} alignItems="center" alignContent="center">
            <Grid item xs={6}>
              <Grid container justify="center">
                {isRowOpen ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              {asset.affected_asset.id && (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <a
                    href={`${portalPath}/${
                      assetTypeToPath[asset.affected_asset.asset_type]
                    }/${asset.affected_asset.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Text variant={'link'}>
                      <LaunchIcon />
                    </Text>
                  </a>
                </Grid>
              )}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="left" classes={{ root: classes.cell }}>
          <Grid container spacing={1} alignItems="center" alignContent="center">
            <Grid item xs={3}>
              <Grid container justify="center">
                <TagHostedOn
                  hosted_on={
                    asset.affected_asset.hosted_on || HostedOnValues.UNKNOWN
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <Text variant="body" component="div" className={classes.text}>
                {asset.affected_asset.asset_type_name} at{' '}
                {asset.affected_asset.name}
              </Text>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="left" classes={{ root: classes.cell }}>
          <Grid item xs={11}>
            <Grid container justify="flex-end">
              {scans[scans.length - 1].current_status ===
              SpecialScanStatusValues.ONGOING
                ? '-'
                : scanHistory?.map(({ description, color }, key) => (
                    <Grid item key={key}>
                      <Tooltip title={description} aria-label={description}>
                        <div
                          className={classes.scanHistoryBlock}
                          data-color={color}
                        ></div>
                      </Tooltip>
                    </Grid>
                  ))}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="left" classes={{ root: classes.cell }}>
          {scans[scans.length - 1].current_status ===
          SpecialScanStatusValues.FINISHED ? (
            <ToolkitTableLastScanStatus
              lastScanStatus={
                asset.exception_type ===
                  PolicyViolationExceptionValues.WONT_FIX &&
                asset.exception_date_created &&
                format(asset.exception_date_created) <= format(new Date())
                  ? 'WontFix'
                  : asset.date_resolved &&
                    format(asset.date_resolved) <= format(new Date())
                  ? 'Protected'
                  : 'Unprotected'
              }
              discoveredDate={asset.date_created}
              lastScanDateCreated={scans[scans.length - 1].date_created}
            />
          ) : scans[scans.length - 1].current_status ===
            SpecialScanStatusValues.ONGOING ? (
            <ToolkitTableSelectedRowScanProgress
              offset={Math.floor(Math.random() * 20)}
            />
          ) : (
            '-'
          )}
        </TableCell>
      </TableRow>
      {isRowOpen &&
        asset.status !== PolicyViolationStatusEnumValues.RESOLVED &&
        asset.exception_type !== PolicyViolationExceptionValues.WONT_FIX && (
          <ToolkitTableSelectedRowDetails
            policyViolationId={asset.id}
            PolicyRuleType={asset.violated_policy_rule.policy_rule_type}
            portalPath={policyViolationPath}
          />
        )}
    </>
  );
}

export default memo<Props>(ToolkitAssetTablePolicyViolationRow);
