//@flow
import React, { useState, memo } from 'react';
import {
  Button,
  TextareaAutosize,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { palette, fontFamilies } from '@dt/theme';
import Text from './Text';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    justifyContent: 'space-around',
  },
  commentForm: {
    fontFamily: fontFamilies.primary,
    padding: 9,
    minWidth: '100%',
    border: `solid 1px ${palette.gray40}`,
    borderRadius: 5,

    // Only allow for vertical resizing of the comment form.
    resize: 'vertical',
    overflowY: 'auto',

    '&:focus': {
      outline: 'none',
      border: `solid 1px ${palette.gray35}`,
    },
  },
  commentFormRadioButton: {
    color: palette.gray20,
    padding: '2px',

    '&$commentFormRadioButtonChecked': {
      color: palette.gray20,
    },
  },
  commentFormRadioButtonChecked: {
    color: palette.gray20,
  },
  commentFormRadioGroup: {
    paddingLeft: '7px',
    marginBottom: '4px',
  },
  commentFormSubmitContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 8,
  },
});

type Props = {|
  +hideSendOptions?: boolean,
  +disabled?: boolean,
  +onSubmit: (note: {
    text: string,
    is_question_for_datatheorem: boolean,
    is_internal_comment: boolean,
  }) => void,
  +onCancel: () => void,
|};

/*
 * Allows the user to comment on a policy violation.
 *
 * @param hideSendOptions - Hide the send options.
 *                          Used primarily by horizon since "internal notes" aren't currently supported.
 * @param disabled - Whether or not the user can interact with this component.
 * @param onSubmit - Triggered event when the user submits/completes the form.
 * @param onCancel - Triggered event when the user cancels the form.
 */
const PolicyViolationsCommentsFormComponent = function PolicyViolationsCommentsForm({
  hideSendOptions,
  disabled,
  onSubmit,
  onCancel,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));

  const [text, setText] = useState('');
  const [sendTo, setSendTo] = useState('sendMsgInternally');

  const handleSubmit = () => {
    onSubmit &&
      onSubmit({
        text,
        is_question_for_datatheorem: sendTo === 'sendMsgToDataTheorem',
        is_internal_comment: sendTo === 'sendMsgInternally',
      });
    setText('');
  };

  return (
    <div className={classes.root}>
      {!hideSendOptions && (
        <RadioGroup
          name="sendToRadioGroup"
          value={sendTo}
          className={classes.commentFormRadioGroup}
          style={{
            display: matchesMd ? 'block' : 'flex',
          }}
          onChange={e => {
            setSendTo(e.target.value);
          }}
        >
          <FormControlLabel
            value="sendMsgInternally"
            disabled={disabled}
            control={
              <Radio
                color="secondary"
                classes={{
                  root: classes.commentFormRadioButton,
                  checked: classes.commentFormRadioButtonChecked,
                }}
              />
            }
            label="Log Internal Note"
          />
          <FormControlLabel
            value="sendMsgToDataTheorem"
            disabled={disabled}
            control={
              <Radio
                color="secondary"
                classes={{
                  root: classes.commentFormRadioButton,
                  checked: classes.commentFormRadioButtonChecked,
                }}
              />
            }
            label="Receive AppSec Advice from Data Theorem"
          />
        </RadioGroup>
      )}

      {sendTo === 'sendMsgToDataTheorem' ? (
        <Typography
          variant="subtitle2"
          style={{ display: 'block', marginBottom: '8px' }}
        >
          Our supporting team will respond to your message soon
        </Typography>
      ) : null}

      <TextareaAutosize
        id="comment-form"
        rows={6}
        rowsMax={6}
        className={classes.commentForm}
        placeholder="Technical questions and/or comments? Insert them here."
        value={text}
        onChange={(e: SyntheticEvent<HTMLInputElement>) => {
          setText(e.currentTarget.value);
        }}
        onKeyPress={(e: SyntheticKeyboardEvent<HTMLInputElement>) => {
          if (e.shiftKey && e.key === 'Enter') {
            handleSubmit();
          }
        }}
      />

      <div className={classes.commentFormSubmitContainer}>
        <Box mr={1}>
          <Button
            variant="contained"
            color="primary"
            aria-label="Save Comment"
            disabled={disabled || text.length <= 0}
            onClick={handleSubmit}
          >
            <Text variant="button" color="inherit">
              Submit
            </Text>
          </Button>
        </Box>

        <Button
          variant="text"
          color="primary"
          aria-label="Cancel"
          onClick={onCancel}
        >
          <Text variant="button" color="inherit">
            Cancel
          </Text>
        </Button>
      </div>
    </div>
  );
};

export const PolicyViolationsCommentsForm = memo<Props>(
  PolicyViolationsCommentsFormComponent,
);
