// @flow
import React, { memo } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/styles';
import { palette } from '@dt/theme';
import Text from '../Text';

const useStyles = makeStyles({
  head: {
    '& th': {
      backgroundColor: palette.white,
      borderColor: palette.gray45,
      padding: 8,
      minWidth: 120,
    },
  },
  wideCell: {
    width: 400,
  },
});

function ToolkitAssetTableHead() {
  const classes = useStyles({});

  return (
    <TableHead classes={{ root: classes.head }}>
      <TableRow>
        <TableCell align="center" colSpan={2}>
          <Text variant="titleS" component="span">
            Asset Details
          </Text>
        </TableCell>
        <TableCell align="center">
          <Text variant="titleS" component="span">
            Status History
          </Text>
        </TableCell>
        <TableCell align="center" classes={{ root: classes.wideCell }}>
          <Text variant="titleS" component="span">
            Current Status
          </Text>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default memo<{}>(ToolkitAssetTableHead);
