//@flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  paper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 8,
    paddingRight: 8,
    height: ({ height }: Props) => {
      return height;
    },
    marginLeft: ({ marginLeft }: Props) => {
      return marginLeft;
    },
  },
});

type Props = {|
  children?: React.Node,
  height?: number,
  marginLeft?: number,
  backgroundColor?: string,
|};

function ChildCard(props) {
  const { children, backgroundColor } = props;
  const classes = useStyles(props);
  return (
    <Paper
      className={classes.paper}
      style={{
        backgroundColor: backgroundColor,
        boxShadow:
          'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0) 0px 2px 4px',
        borderRadiusTopRight: 'none',
        borderRadiusTopLeft: 'none',
      }}
    >
      {children}
    </Paper>
  );
}

ChildCard.defaultProps = {
  marginLeft: 0,
  height: 0,
};

export default React.memo<Props>(ChildCard);
