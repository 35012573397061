// @flow
import React, { PureComponent } from 'react';
import { Button } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';

import { type ShadowMetadataCountType } from '@dt/user-api/mobile_apps';

import ChildCard from './../ChildCard';
import { palette } from '@dt/theme';

const SHADOW_SCAN_CHILD_CARD_HEIGHT = 25;

type Props = {
  shadowMetadataCount: ?ShadowMetadataCountType,
  onPreviewClick: () => void,
  ...
};

class ShadowScanChildCard extends PureComponent<Props> {
  render() {
    const { shadowMetadataCount, onPreviewClick } = this.props;

    if (!shadowMetadataCount || !shadowMetadataCount.ALL) return null;

    return (
      <ChildCard
        height={SHADOW_SCAN_CHILD_CARD_HEIGHT}
        marginLeft={5}
        backgroundColor={palette.yellow50}
      >
        <p style={{ fontSize: '12px' }}>
          <span>
            You are unsubscribed to {shadowMetadataCount.ALL} security issues.
          </span>
          {(shadowMetadataCount.P1 || 0) > 0 ? (
            <span>This includes {shadowMetadataCount.P1} P1 issues.</span>
          ) : (
            <span />
          )}
        </p>
        <Button
          color="primary"
          variant="text"
          size="small"
          onClick={onPreviewClick}
        >
          Preview <LaunchIcon style={{ width: '14px', marginLeft: 4 }} />
        </Button>
      </ChildCard>
    );
  }

  static getHeight = (shadowMetadataCount: ?ShadowMetadataCountType) => {
    if (!shadowMetadataCount || !shadowMetadataCount.ALL) return 0;
    return SHADOW_SCAN_CHILD_CARD_HEIGHT;
  };
}

export default ShadowScanChildCard;
