//@flow
import React, { useEffect } from 'react';
import { CenteredCircularProgress } from '@dt/material-components';
import { UnauthorizedError } from './../components/errors';
import { useSession, useAuthorization } from '../session';
import { NotFoundPage } from '@dt/components';
import { useDispatch, useSelector } from 'react-redux';
import { setTemporaryToken } from '@dt/horizon-api';
import { PublicAppContext } from './shared_links/public_app/usePublicApp';
import { ErrorState } from '@dt/components';
import type { State as ReduxState } from './store_state_type';

/*
 * @deprecated - Only intended to be used for legacy api containers.
 */
const ApiAccessControls = function ApiAccessControls({
  children,
}: {
  children: any,
}) {
  const {
    loading: sessionLoading,
    error: sessionError,
    data: sessionData,
  } = useSession();
  const { isAuthorized } = useAuthorization(sessionData, [
    'can_access_api_inspect',
  ]);

  // Error State.
  if (sessionError) {
    return <ErrorState error="Currently unable to authenticate you." />;
  }

  // Loading State.
  if (sessionLoading || !sessionData) {
    return <CenteredCircularProgress />;
  }

  // Unauthorized State.
  if (!isAuthorized) {
    return <UnauthorizedError />;
  }

  return children;
};

/*
 * High order component to augment pages with "api" access controls.
 *
 * @deprecated - Only intended to be used for legacy api containers.
 */
export const accessControlsApi = (Container: any) => {
  return (props: any) => (
    <ApiAccessControls>
      <Container {...props} />
    </ApiAccessControls>
  );
};

/*
 * @deprecated - Only intended to be used for legacy share containers.
 */
const SharedAppAccessControls = function SharedAccessControls({
  token,
  children,
}: {
  token: string,
  children: any,
}) {
  const dispatch = useDispatch();
  const isReady = useSelector((state: ReduxState) => state.ready);

  useEffect(() => {
    if (token) {
      setTemporaryToken(token);
    }
  }, [dispatch, token]);

  if (!token) return <NotFoundPage />;
  if (!isReady) return <CenteredCircularProgress />;

  return (
    <PublicAppContext.Provider value={true}>
      {children}
    </PublicAppContext.Provider>
  );
};

/*
 * High order component to augment pages with "share" access controls.
 *
 * @deprecated - Only intended to be used for legacy share containers.
 */
export const accessControlsShare = (Container: any) => {
  return (props: any) => (
    <SharedAppAccessControls {...props}>
      <Container {...props} />
    </SharedAppAccessControls>
  );
};

/*
 * @deprecated - Only intended to be used for legacy landing page.
 */
const LandingPageAppAccessControls = function SharedAccessControls({
  children,
}: {
  token: string,
  children: any,
}) {
  const {
    loading: sessionLoading,
    error: sessionError,
    data: sessionData,
  } = useSession();
  const { isAuthorized } = useAuthorization(sessionData, []);

  // Error State.
  if (sessionError) {
    return <ErrorState error="Currently unable to authenticate you." />;
  }

  // Loading State.
  if (sessionLoading || !sessionData) {
    return <CenteredCircularProgress />;
  }

  // Unauthorized State.
  if (!isAuthorized) {
    return <UnauthorizedError />;
  }

  return children;
};

/*
 * High order component to augment pages with "landing page" access controls.
 *
 * @deprecated - Only intended to be used for legacy landing page.
 */
export const accessControlsLandingPage = (Container: any) => {
  return (props: any) => (
    <LandingPageAppAccessControls {...props}>
      <Container {...props} />
    </LandingPageAppAccessControls>
  );
};
