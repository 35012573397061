//@flow
import React, { memo, type Node } from 'react';
import { NavLink, Route } from 'react-router-dom';
import ExtLink from './ExtLink';
import { type Properties } from 'csstype';

type Props = {
  children: Node,
  to: string,
  exact?: boolean,
  strict?: boolean,
  replace?: boolean,
  className?: string,
  redirect?: boolean,
  onClick?: () => void,
  activeStyle: Properties<>,
};

function MobileNavLink({
  children,
  activeStyle,
  to,
  exact = false,
  redirect = false,
  strict,
  replace,
  ...rest
}: Props) {
  return redirect ? (
    <ExtLink {...rest} to={to} target={redirect ? '_blank' : '_self'}>
      {children}
    </ExtLink>
  ) : (
    <Route path={to} exact={exact} strict={strict}>
      {() => {
        if (to) {
          return (
            <NavLink
              {...rest}
              to={to}
              replace={replace}
              style={{ display: 'block' }}
            >
              {children}
            </NavLink>
          );
        }

        return <>{children}</>;
      }}
    </Route>
  );
}

export default memo<Props>(MobileNavLink);
