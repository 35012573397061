// @flow
import { createAction } from 'redux-actions';

import type { PiiTypeCustomizationConfig } from '@dt/horizon-api';

export const hackExtractMounted = createAction<
  'hack_extract/mounted',
  [],
  { key: 'hack_extract' },
>('hack_extract/mounted', () => ({ key: 'hack_extract' }));

export const hackExtractUnmounted = createAction<'unmounted/hack_extract'>(
  'unmounted/hack_extract',
);

export const hackExtractStartClicked = createAction<
  'hack_extract/start/clicked',
  [PiiTypeCustomizationConfig],
  {| configuration: PiiTypeCustomizationConfig |},
>('hack_extract/start/clicked', configuration => ({ configuration }));

export const hackExtractShowResultsClicked = createAction<
  'hack_extract/show-results/clicked',
>('hack_extract/show-results/clicked');

export const hackExtractHideResultsClicked = createAction<
  'hack_extract/hide-results/clicked',
>('hack_extract/hide-results/clicked');
